<template>
    <div style="background-color: #f2f2f2; margin-top: 8px">
        <el-card style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" size="small" label-width="85px">
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="机构组名称">
                            <el-select v-model="form.deptGroupCode" @change="handleSelectDeptGroup" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="item in deptGroups"
                                    :label="item.name"
                                    :value="item.code"
                                    :key="item.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="form.deptCode" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="item in deptList"
                                    :label="item.name"
                                    :value="item.code"
                                    :key="item.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="员工">
                            <el-input v-model="form.staffRealNameOrMobile" placeholder="姓名/手机号"></el-input
                        ></el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col>
                        <el-form-item label="推广会员日期" label-width="100px">
                            <el-date-picker
                                v-model="form.dates"
                                type="daterange"
                                :unlink-panels="true"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="searchHandleQuery"
                size="small"
                v-if="hasPrivilege('menu.report.distributionMemberDetail.open')"
            >
                查询
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.report.distributionMemberDetail.export')"
            >
                导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card style="margin-top: 8px">
            <el-table
                id="table"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="460"
            >
                <el-table-column label="序号" type="index" width="110" align="center" fixed="left" />
                <el-table-column prop="staffRealName" label="员工姓名" width="180" v-if="showColumn('staffRealName')" />
                <el-table-column prop="staffMobile" label="手机号" width="180" v-if="showColumn('staffMobile')" />
                <el-table-column prop="deptName" label="机构名称" width="230" v-if="showColumn('deptName')" />
                <el-table-column
                    prop="deptGroupName"
                    label="机构组名称"
                    width="240"
                    v-if="showColumn('deptGroupName')"
                />
                <el-table-column
                    prop="promotionMemberCount"
                    label="推广会员数量"
                    width="150"
                    v-if="showColumn('promotionMemberCount')"
                />
                <el-table-column
                    prop="settledAmount"
                    label="可用商分金额"
                    width="150"
                    v-if="showColumn('settledAmount')"
                >
                    <template slot="header">
                        <el-tooltip effect="light" content="截止当前时间，员工在朗勤心选中可用商分" placement="top">
                            <span>可用商分金额 <i class="el-icon-info"></i></span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="exchangeAmount"
                    label="已兑换商分金额"
                    width="150"
                    v-if="showColumn('exchangeAmount')"
                >
                    <template slot="header">
                        <el-tooltip
                            effect="light"
                            content="截止当前时间，员工在收银系统中累计兑换商分金额之和"
                            placement="top"
                        >
                            <span>已兑换商分金额 <i class="el-icon-info"></i></span>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <ef-pagination
                :total="total"
                :default-limit="form.limit"
                :current-page="form.page"
                @pageChange="pageChange"
                :sizes-style-flag="true"
            />
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
    </div>
</template>

<script>
import EfPagination from 'components/EfPagination';
import UrlUtils from 'js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
import Util from 'js/Util';

export default {
    name: 'DistributionMemberDetail',
    components: { EfPagination, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        //初始化时间
        function getMonthStartDayStr() {
            // 获取本月第一天
            const now = new Date();
            return now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + '01';
        }
        function getNowStr() {
            //获取当天
            const now = new Date();
            return now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
        }
        return {
            form: {
                deptGroupCode: null,
                deptCode: null,
                dates: [getMonthStartDayStr(), getNowStr()],
                startTime: null,
                endTime: null,
                staffRealNameOrMobile: '',
                page: 1,
                limit: 10,
            },
            url: {
                queryDeptByGroup: '/system/dept/deptTree',
            },
            deptGroups: [],
            deptList: [],
            total: 0,
            tableData: [],
        };
    },

    mounted() {
        //查询机构组
        UrlUtils.DeptGroup(this, (rst) => {
            this.deptGroups = rst;
            if (this.deptGroups && this.deptGroups.length > 0) {
                // 默认第一个
                this.form.deptGroupCode = this.deptGroups[0].code;
                //触发机构组查询机构
                this.handleSelectDeptGroup(this.form.deptGroupCode, this.handleQuery);
            }
        });
    },
    methods: {
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        async handleQuery() {
            if (Util.isEmpty(this.form.deptCode) || Util.isEmpty(this.form.deptGroupCode)) {
                this.$message.error('请选择机构组和机构');
                return;
            }
            if (this.form.dates && this.form.dates.length > 0) {
                this.form.startTime = this.form.dates[0];
                this.form.endTime = this.form.dates[1];
            } else {
                this.$message.error('请选择时间范围');
                return;
            }
            const rst = await this.$efApi.distributionMemberDetailApi.distributionMemberDetailList(this.form);
            this.tableData = rst.data;
            this.total = rst.count;
        },
        handleSelectDeptGroup(groupCode, fun) {
            this.deptList = [];
            this.form.deptCode = '';
            UrlUtils.QueryRemote(this, this.url.queryDeptByGroup + '?deptGroupCode=' + groupCode, (rst) => {
                this.deptList = rst;
                if (this.deptList && this.deptList.length > 0) {
                    //每次变换默认选择第一个
                    this.form.deptCode = this.deptList[0].code;
                }
                //存在函数在回调后指定函数
                if (fun) {
                    fun();
                }
            });
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.handleQuery();
        },
        handleExport() {
            UrlUtils.Export(
                this,
                '员工推广会员数据明细表',
                `${this.$efApi.distributionMemberDeptCountApi.baseUrl}/exportExcelPromotionMemberDetailForSystemC`,
                this.form
            );
        },
        handlePrint() {
            this.printHTML('table', this.$route.meta.title);
        },
    },
};
</script>
